import React from "react";
import { Row, Col, Popover,Select, Modal } from "antd";
import Router from "next/router";
import HostConfig from "$ACTIONS/Host.config";
import CMSOBJ from "$DATA/stage.live.static.id";
import ImageWithFallback from "@/ImageWithFallback/imgLocal";
import {translate} from "$ACTIONS/Translate";
import { isGuest, getSpecificImageFormat } from "../../actions/helper";
import LazyLoad from "react-lazyload";

const { Option } = Select;

const showTelegramQRCood=()=>{
    const QRCood = Modal.info({
        className: "telegram-qrcode-modal",
        title: "",
        centered: true,
        okText: translate("关闭"),
        closable: true,
        icon:null,
        content: (
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <img src={`${process.env.BASE_PATH}/img/footer/social/Contact_TG-VN.jpg`} />
            </div>
        ),
        onOk: () => {
            QRCood.destroy()
        },
    })
}

const renderIconListByFooterData = (field, footerData, supportFormat)=> {
    footerData = footerData || {};
    return !Array.isArray(footerData[field]) ? null : footerData[field].map((v,i)=>{
        let attrs = {style:{}};

        if (v.desktop_forwarding_url) {
            attrs.onClick = (function(v) {
                return () => {
                    window.open(
                        v.desktop_forwarding_url,
                        "_blank"
                    );
                }})(v);
        }
        
        if (v.desktop_text && v.desktop_text.toLowerCase().includes("telegram")) {
            attrs.onClick = showTelegramQRCood;
        }

        let deskImg = getSpecificImageFormat(v, 'desktop_image', supportFormat);
        let deskImgHover = getSpecificImageFormat(v, 'desktop_image_hover', supportFormat);

        return (<Popover
                  content={v?.desktop_text !== "" ? v.desktop_text : ""}
                  title=""
                  trigger="hover"
                  overlayClassName="footer-popover"
              >
                  <li className={"icon-item2"} {...attrs}
                      onMouseEnter={e=>e.target.setAttribute('src', deskImgHover)}
                      onMouseLeave={e=>e.target.setAttribute('src', deskImg)}>
                      <img loading="lazy" src={deskImg} style={{height:30, display:'inline-block'}} />
                      <img src={deskImgHover} style={{position:'fixed', top:-9999}} />
                  </li>
              </Popover>)
        })
};
export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Menudata: "",
            HlepMenudata: "",
            showWechatQrcode: false,
            language: translate("越南文"),
            languageArray: [
                { name: "China", language: "中文", path: "/cn" },
                { name: "Thailand", language: translate("泰文"), path: "/th" },
                { name: "Vietnam", language: translate("越南文"), path:"/vn"},
            ],
        };
    }
    handleChange=(e) => {
        if(typeof window !== "undefined"){
            const selectedLang = this.state.languageArray.find(lang => lang.path === e);
            this.setState({ language : selectedLang?.name })
            window.location.href = e;
        }
    }
    render() {
        const {language,languageArray} = this.state;
        const { isIndexPage }= this.props;
        const isLogin = typeof window !== "undefined" && !!localStorage.getItem("access_token") && localStorage.getItem("access_token");
        let sponsorImgsrc0, sponsorImgsrc1;
        const [supportFormat, footerData] = this.props.footerData;
        if (Array.isArray(footerData?.sponsorship)) {
            let len = footerData.sponsorship.length;
            if (len >= 1) { sponsorImgsrc0 = getSpecificImageFormat(footerData.sponsorship[0], 'desktop_image', supportFormat) }
            if (len >= 2) { sponsorImgsrc1 = getSpecificImageFormat(footerData.sponsorship[1], 'desktop_image', supportFormat) }
        }
        return (
            <React.Fragment>
                 {isIndexPage && <LazyLoad height={454} offset={100}>
                    <div className="common-distance-wrap cooperate-wrap">
                        <div className="common-distance clear-padding">
                            <h2 className="title">{translate("赞助")}</h2>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                margin: "0 20px",
                            }}>
                                <Col span={12}>
                                    {/* <div className="cooperate-content" style={{backgroundImage:`url(${sponsorImgsrc0})`}}></div> */}
                                    <LazyLoad height={808} offset={72} once style={{
                                        display: "flex",
                                        justifyContent:"flex-end",
                                    }}>
                                        <ImageWithFallback
                                            src={sponsorImgsrc0}
                                            width={500}
                                            height={300}
                                            alt="app-picture"
                                        />
                                    </LazyLoad>
                                </Col>
                                <Col span={12}>
                                    <LazyLoad height={808} offset={72} once>
                                        <ImageWithFallback
                                            src={sponsorImgsrc1}
                                            width={600}
                                            height={300}
                                            alt="app-picture"
                                        />
                                    </LazyLoad>
                                </Col>
                            </div>
                        </div>
                    </div>
                </LazyLoad>}
                <div className="common-distance-wrap footer-platform-wrap">
                    <div className="common-distance">
                        <Row className="footer-platform">
                            <Col span={7}>
                                <h4>{translate("支付方式")}</h4>
                                <ul className="icon-list">
                                    {renderIconListByFooterData('payment_options', footerData, supportFormat)}
                                </ul>
                            </Col>
                            <Col span={6}>
                                <h4>{translate("关注我们")}</h4>
                                <ul className="icon-list">
                                    {renderIconListByFooterData('social_media', footerData, supportFormat)}
                                </ul>
                            </Col>
                            <Col span={4}>
                                <h4>{translate("博彩责任")}</h4>
                                <ul className="icon-list">
                                    {renderIconListByFooterData('regulation', footerData, supportFormat)}
                                </ul>
                            </Col>
                            <Col span={4}>
                                <h4>{translate("信息安全")}</h4>
                                <ul className="icon-list">
                                    {renderIconListByFooterData('info_security', footerData, supportFormat)}
                                </ul>
                            </Col>
                            {!isLogin && <Col span={3}>
                                <Select
                                    size="large"
                                    dropdownClassName="footer-small-drop"
                                    className={`select-language-box ${language}`}
                                    defaultValue={language}
                                    onChange={this.handleChange}
                                    suffixIcon={<img src={`${process.env.BASE_PATH}/img/icon/icon-extand.svg`} alt="extand"/>}
                                >
                                    {languageArray.map((item) => (
                                        <Option
                                            key={item.path}
                                        >   
                                            <img src={`${process.env.BASE_PATH}/img/footer/language/${item.name}.svg`}/>
                                            <span>{item.language}</span>
                                        </Option>
                                    ))}
                                </Select>
                            </Col>}
                        </Row>
                        <div>
                            <h4>{translate("游戏平台")}</h4>
                            <Row gutter={16}>
                                {!Array.isArray(footerData?.gaming_platform) ? null : footerData.gaming_platform.map((v, i) => {
                                    let deskImg = getSpecificImageFormat(v, 'desktop_image', supportFormat);
                                    let deskImgHover = getSpecificImageFormat(v, 'desktop_image_hover', supportFormat);
                                    return (
                                        <Col
                                            span={3}
                                            style={{
                                                width: "140px",
                                                height: "36px",
                                                marginTop: "10px",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                            key={i}
                                            onClick={() => {
                                                const url = v?.desktop_forwarding_url;
                                                if (url) {
                                                    window.open(url, "_blank");
                                                }
                                            }}
                                        >
                                            <img loading="lazy"
                                                src={deskImg||'/cn/img/logo.svg'}
                                                onMouseEnter={e=>e.target.setAttribute('src', deskImgHover)}
                                                onMouseLeave={e=>e.target.setAttribute('src', deskImg)}
                                                width={140}
                                                height={36}
                                                alt={v.desktop_text}
                                            />
                                            <img src={deskImgHover} style={{position:'fixed', top:-9999}} />
                                        </Col>
                                    );
                                })}
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="common-distance-wrap footer-copyright-wrap">
                    <div className="common-distance clear-padding">
                        <Row>
                            <Col span={3} className="copyright-picture">
                                <ImageWithFallback
                                    src={`/vn/img/home/part/img.png`}
                                    width={122}
                                    height={118}
                                    alt={"card"}
                                    fallbackSrc="/vn/img/logo/logo.svg"
                                />
                            </Col>
                            <Col span={21} className="copyright-article">
                                <p>
                                    www.fun88.com is operated by E Gambling
                                    Montenegro d.o.o. (81000 Podgorica,
                                    Moskovska br. 65., reg. no.5-0615951) in
                                    cooperation with OG GLOBAL ACCESS LIMITED
                                    (P.O. Box 3340, Road Town, Tortola, British
                                    Virgin Islands.
                                </p>
                                <p>
                                    E Gambling Montenegro d.o.o. (81000
                                    Podgorica, Moskovska br. 65., reg.
                                    no.5-0615951) hereby certifies that under
                                    the concession (serial no. AA 001588,
                                    numeric no. 133-01/15 4A and 1ER) and
                                    approval (no. 02/01-118/4), organize and
                                    operate games of chance in Montenegro on
                                    website www.fun88.com , in accordance with
                                    the Agreement, concluded between E Gambling
                                    Montenegro d.o.o. and OG GLOBAL ACCESS
                                    LIMITED (P.O. Box 3340, Road Town, Tortola,
                                    British Virgin Islands, reg. no. 1923130) on
                                    07 October 2021
                                </p>
                            </Col>
                        </Row>
                        <div className="copyright-content">
                            <p>
                                {translate(
                                    "Fun88乐天堂属于OG GLOBAL ACCESS LIMITED 运营"
                                )}
                            </p>
                            <p>
                                {translate(
                                    "FUN88乐天堂于2019年3月1日起获得菲律宾政府PAGCOR授权并监管"
                                )}
                            </p>
                            <p>
                                {translate("FUN88乐天堂 © 版权所有 违者必究")}
                            </p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
